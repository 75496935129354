import React, { useContext, useState, useEffect } from 'react';
import * as dayjs from 'dayjs';
import { mutate } from 'swr';
import { Box, Flex, Stack, List, ListItem, Heading, Text, AccordionItem, AccordionHeader, Accordion, AccordionPanel, AccordionIcon, Select } from '@chakra-ui/core';
import { Context } from '../context/Context'
import globalStyles from '../../globalStyles';
import { Button, Spin, Table } from 'antd';
import axios from 'axios';
import { useToasts } from 'react-toast-notifications';
import { MdFileDownload } from 'react-icons/md'
import { useCategories, useSubCategories } from '../categories/helpers/fetcher';
import { useBidsForSubItem } from './helpers/fetcher';
// import Can from '../utils/rbac/Can';
import config from '../utils/data/config';
import { useTenders } from '../tenders/helpers/fetcher';
import { renderAdminAction } from '../utils/renderRole';
import { formatCurrency, formatNumber } from '../utils/formatCurrency';
import { downloadFile } from '../utils/download';

const { Column } = Table;

function ManageBids() {
    const { addToast } = useToasts()
    const { user } = useContext(Context);
    const [selectedCategory, setSelectedCategory] = useState("")
    const [selectedSubCategory, setSelectedSubCategory] = useState("")
    const [approving, setApproving] = useState(false)

    const [selectedTender, setSelectedTender] = useState(null)
    const [selectedSubItem, setSelectedSubItem] = useState(null)

    const filterCategory = selectedCategory ? `?categoryid=${selectedCategory}` : ''
    const filterSubCategory = selectedSubCategory ? `&subcategoryid=${selectedSubCategory}` : ''

    const filterPath = filterCategory + filterSubCategory;

    const { data: categories } = useCategories("fetchcategoriesadminandprocurementuserbelong", user.token)
    const { subcategories } = useSubCategories("fetchsubcategorybycategoryid", user.token, selectedCategory)

    const { tenders, isLoading } = useTenders(`filtertenders${filterPath}`, user.token)
    const { bids, isLoading: loadingBids } = useBidsForSubItem(selectedSubItem ? `fetchbidsbysubitemid/${selectedSubItem}` : null, user.token)

    useEffect(() => {
        if (!selectedTender && tenders.length) {
            setSelectedTender(tenders.filter(tender => tender.tenderItemsObject)[0])
        }

    }, [tenders, selectedTender])


    const approveBid = async (id) => {
        setApproving(true)
        const body = { id }
        try {
            const res = await axios.post(`${config.baseUrl}/approvebid`, body, { headers: { "Authorization": `Bearer ${user.token}` } })
            if (res.data.error) {
                showToast("error", res.data.error)
            }
            else {
                showToast("success", "Successfully approved bid")
                mutate([`${config.baseUrl}/fetchbidsbysubitemid/${selectedSubItem}`, user.token])
                // onClose()
            }
        }
        catch (e) {
            console.log(e.response)
            showToast("error", e.message)
        } finally {
            setApproving(false)
        }
    }

    const showToast = (status, message) => {
        addToast(message, { appearance: status })
    }


    return (
        <>
            <Box bg={globalStyles.green} px={4} py={2} rounded="md">
                <Stack isInline spacing={8} align="center">
                    <Text color="white" fontSize="md">Filter By:</Text>
                    <Select
                        w="25%"
                        placeholder="Tenders"
                        value={selectedTender?.id}
                        id="tenders"
                        onChange={(e) => {
                            setSelectedSubItem(null)
                            const found = tenders.find(item => item.id === parseInt(e.target.value))
                            setSelectedTender(found)

                        }}
                        bg={globalStyles.lightGrey}
                        border="0"
                        focusBorderColor={globalStyles.green}
                    >
                        {
                            tenders.filter(data => data.tenderItemsObject).map(item => (
                                <option key={item.id} value={item.id}>{item.tenderName}</option>
                            ))
                        }
                    </Select>
                    <Select
                        w="25%"
                        placeholder="Category"
                        id="category"
                        onChange={(e) => {
                            setSelectedSubItem(null)
                            setSelectedCategory(e.target.value)
                        }}
                        bg={globalStyles.lightGrey}
                        border="0"
                        focusBorderColor={globalStyles.green}
                    >
                        {
                            categories.map(item => (
                                <option key={item.id} value={item.id}>{item.category}</option>
                            ))
                        }
                    </Select>
                    <Select
                        w="25%"
                        placeholder="Sub Category"
                        id="subcategoryId"
                        bg={globalStyles.lightGrey}
                        onChange={(e) => {
                            setSelectedSubCategory(e.target.value)
                        }}
                        border="0"
                        focusBorderColor={globalStyles.green}
                    >
                        {
                            subcategories.map(subcategory => (
                                <option key={subcategory.id} value={subcategory.id}>{subcategory.subcategory}</option>
                            ))
                        }
                    </Select>
                </Stack>
            </Box>
            <br />
            {!isLoading && selectedTender ?
                <>
                    <Flex justify="space-between" mt="2">

                        <Stack isInline spacing={4}>
                            <Stack spacing={1}>
                                <Heading as="h6" size="xs">Tender: <span style={{ color: globalStyles.green }}>{selectedTender.tenderName}</span></Heading>
                                {/* <Text fontSize="xs" color={globalStyles.darkGrey}>Due Date:  {dayjs(selectedTender.dueDate).format('DD MMM YYYY')}</Text>
                                <Text fontSize="xs" color={globalStyles.darkGrey}>Created:   {dayjs(selectedTender.created_at).format('DD MMM YYYY')}</Text> */}
                            </Stack>
                        </Stack>
                        {/* <Box>
                            {selectedTender.status === 0 ? <Heading as="h6" size="xs" color={globalStyles.green}>Open</Heading> : <Heading as="h6" size="xs" color={globalStyles.red}>Closed</Heading>}
                        </Box> */}
                    </Flex>
                    <br />
                    <Flex w="100%">
                        <Box w="25%" overflow="scroll" h="80vh" className="visible-scrollbar" shadow="md" borderLeftWidth="1px">
                            <Accordion allowToggle>
                                {JSON.parse(selectedTender.tenderItemsObject).map(item => {
                                    return (
                                        <AccordionItem key={item.id}>
                                            <AccordionHeader>
                                                <Box flex="1" textAlign="left">
                                                    <Heading as="h6" size="xs">{item.itemName}</Heading>

                                                    <Text fontSize="xs" color={globalStyles.darkGrey}>Delivery Term: <span style={{ color: globalStyles.green }}>{item.deliveryTerm === 1 ? 'Staggered' : 'Bulk'}</span></Text>
                                                    <Text fontSize="xs" color={globalStyles.darkGrey}>Fixed Prices: <span style={{ color: globalStyles.green }}>{item.fixedPrice === 1 ? 'Yes' : 'No'}</span></Text>
                                                    <Text fontSize="xs" color={globalStyles.darkGrey}>Fixed Prices: <span style={{ color: globalStyles.green }}>{item.invoicePaymentTerms}</span>Days</Text>
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionHeader>
                                            <AccordionPanel pb={4}>
                                                <List styleType="disc">
                                                    {item.subItems.map(subItem =>
                                                        <ListItem
                                                            p="4"
                                                            bg={selectedSubItem === subItem.id ? "#e1f7f4" : "white"}
                                                            cursor="pointer"
                                                            onClick={() => setSelectedSubItem(subItem.id)}
                                                            key={subItem.id}>{subItem.materialDescription}
                                                        </ListItem>
                                                    )
                                                    }
                                                </List>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    )
                                })}
                            </Accordion>
                        </Box>
                        <Box w="75%" px="2" borderWidth="1px">
                            <Box>

                            </Box>
                            <Table size="middle" loading={loadingBids} pagination={false} rowKey={record => record.id} dataSource={bids ?? []}>
                                <Column
                                    title={<Heading as="h6" size="xs">Supplier</Heading>}
                                    dataIndex='supplier'
                                    key='supplier'

                                />
                                <Column
                                    title={<Heading as="h6" size="xs">Unit Price</Heading>}
                                    dataIndex='unitprice'
                                    key='unitprice'
                                    render={(text) => formatCurrency(parseInt(text))}
                                />
                                <Column
                                    title={<Heading as="h6" size="xs">Bid Quantity</Heading>}
                                    dataIndex='supplierQuantity'
                                    key='supplierQuantity'
                                    render={(text) => formatNumber(parseInt(text))}
                                />
                                <Column
                                    title={<Heading as="h6" size="xs">Total Bid Price</Heading>}
                                    dataIndex='totalbidprice'
                                    key='totalbidprice'
                                    render={(text) => formatCurrency(parseInt(text))}
                                />
                                <Column
                                    title={<Heading as="h6" size="xs">Delivery Time</Heading>}
                                    dataIndex='deliveryDate'
                                    key='deliveryDate'
                                    render={(text) => dayjs(text).format('DD/MM/YYYY')}
                                />
                                <Column
                                    title={<Heading as="h6" size="xs">Status</Heading>}
                                    dataIndex='adminaction'
                                    key='adminaction'
                                    render={(text) => {
                                        return renderAdminAction(text)
                                    }}
                                />
                                <Column
                                    title={<Heading as="h6" size="xs">Attachment</Heading>}
                                    dataIndex='attachement'
                                    key='attachement'
                                    render={(text) => {
                                        if (!text) {
                                            return
                                        }
                                        return (
                                            <Button
                                                onClick={() => downloadFile(text)}
                                                variant="outline">
                                                <MdFileDownload />
                                            </Button>
                                        )
                                    }}
                                />
                                <Column
                                    title={<Heading as="h6" size="xs">Action</Heading>}
                                    dataIndex='id'
                                    key='id'
                                    render={(text, record) => {
                                        return (
                                            <Button
                                                loading={approving}
                                                onClick={() => approveBid(text)}
                                                disabled={record.adminaction === '3'}
                                                bg="teal"
                                                variant="outline">
                                                {record.adminaction === '3' ? 'Approved' : 'Approve'}
                                            </Button>
                                        )
                                    }}
                                />
                            </Table>
                        </Box>
                    </Flex>
                </>
                :
                <Flex justify="center" h="80vh" align="center">
                    <Spin />
                </Flex>
            }

        </>
    )
}

export default ManageBids;